<template>
  <div>
    <spinner-loader :loading="loading" />
    <donor-form
      v-if="!loading"
      :donor="donor"
      :submit="editDonor"
    />
  </div>
</template>
<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import DonorForm from '@/common/components/DonationManagement/DonorForm.vue'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'

export default {
  name: 'EditDonor',
  components: {
    DonorForm,
    SpinnerLoader,
  },
  data() {
    return {
      donor: {},
      loading: true,
    }
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    const {
      getValidationState,
    } = formValidation()

    return {
      getValidationState,
      successfulOperationAlert,

    }
  },
  created() {
    this.getUser()
  },
  methods: {
    getUser() {
      this.$portalUsers.get(`internalops/user/${this.$route.params.id}`, {
        params: {
          entity_id: this.$store.getters['mainEntity/getEntityId'],
        },
      }).then(({ data }) => {
        this.donor = {
          userId: data.data.profile?.user_id,
          first_name: data.data.profile?.first_name,
          last_name: data.data.profile?.last_name,
          email: data.data.email,
          phone_number: data.data.phone_number,
          city: data.data.profile?.city,
          state: data.data.profile?.state,
          country: data.data.profile?.country,
          lat: data.data.profile?.lat,
          lng: data.data.profile?.lng,
          postal_code: data.data.profile?.postal_code,
          street: data.data.profile?.street,
        }
      }).catch(() => {
        this.donor = {}
      }).finally(() => {
        this.loading = false
      })
    },
    editDonor() {
      return this.$portalUsers.post('internalops/donor?_method=PUT', this.donor).then(() => {
        this.successfulOperationAlert('Donor is update successfully')
        this.$store.commit('donor/setUser', this.donor.email)
        this.$router.push({ name: 'donations-list' })
      })
    },
  },
}
</script>
<style lang="scss">
</style>
